import { CookiesService } from './cookies.service';
import { Injectable, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnInit {

  public userRole$ = new BehaviorSubject('');

  constructor(
    private cookiesService: CookiesService
  ) { }

  ngOnInit(): void {

  }


  public getUserRole(): string {
    let userRole: string = '';
    userRole = this.cookiesService.getCookieByName("userRole");
    return userRole;
  }


  public isLoggedIn$(): Observable<boolean> {
    const isLoggedIn: boolean = !!this.cookiesService.getCookieByName("accessToken");
    console.log("isLoggedIn", isLoggedIn)
    return of(isLoggedIn);
  }

  ///////////////// Single Role //////////////////

  public isSuperAdmin$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Super_Admin') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isAdmin$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Admin') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isAnnotator$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Annotator') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isCreator$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Creator') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isDataEngineer$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Data Engineer') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isVendor$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Vendor') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isApprover$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Approver') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isAnnotationScientist$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Annotation Scientist') {
      return of(true);
    } else {
      return of(false);
    }
  }

  public isViewer$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Viewer') {
      return of(true);
    } else {
      return of(false);
    }
  }

  ///////////////// Checking Two Roles //////////////////

  public isSuperAdminOrAdmin$(): Observable<boolean> {
    const role = this.getUserRole();
    if (role === 'Super_Admin') {
      return of(true);
    } else if (role === 'Admin') {
      return of(true);
    } else {
      return of(false);
    }
  }


}
