import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { authenticationInterceptor, unauthErrorInterceptor } from './shared/interceptor/authentication.interceptor';
import { InterceptorsProvider } from './shared/interceptor/intercepors.provider';
//import { InterceptorsProvider } from './shared/interceptor/intercepors.provider';

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    NgxPaginationModule,
    NgxSpinnerModule,
    ScrollingModule,
    PdfViewerModule,
    // NgxImageZoomModule,
    ToastrModule.forRoot(
      {
        preventDuplicates: true,
        maxOpened: 1,
        autoDismiss: true
      }
    )




  ],
  providers: [
    Title,
    //provideHttpClient(withInterceptors([authenticationInterceptor, unauthErrorInterceptor]))
    InterceptorsProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
