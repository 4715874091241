import { FreeTrialService } from 'src/app/modules/free-trial/services/free-trial.service';
import { CookiesService } from 'src/app/shared/services/cookies.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BaseApiUrl } from 'src/app/shared/base_api/base-api-urls';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnDestroy {

  public notificationMessageDetails$ = new BehaviorSubject<any>({});

  public unsubscriber$ = new Subject();

  constructor(
    private http: HttpClient,
    private cookiesService: CookiesService,
    private freeTrialService: FreeTrialService,
    public router: Router,
    public notificationService: NotificationService,
  ) { }



  public login(data): Observable<any> {
    const fd = new FormData();
    fd.append('data', JSON.stringify(data));
    fd.append('username', data.username);
    fd.append('password', data.password);
    fd.append('company_name', data.company_name);
    return this.http.post(BaseApiUrl.LoginURL, fd, {
      responseType: 'text'
    });
  }

  public logout(data): Observable<any> {
    console.log("logout testing");
    this.resetBrowserMemory();

    const fd = new FormData();
    fd.append('username', data.username);
    fd.append('company_name', data.company_name);
    return this.http.post(BaseApiUrl.LogOutURL, fd)
  }

  public resetBrowserMemory(): void {
    if (this.cookiesService.checkCookie('accessToken')) {
      this.cookiesService.deleteCookie('accessToken');
    }

    if (this.cookiesService.checkCookie('refreshToken')) {
      this.cookiesService.deleteCookie('refreshToken');
    }

    this.cookiesService.deleteAllCookies();
    localStorage.clear();
    sessionStorage.clear();

    this.freeTrialService.isFreeTrialUser.set(false);
    this.freeTrialService.isFreeTrialExpired.set(false);
    this.freeTrialService.totalFreeTrialPagesConsumed$.set(0);
  }


  public getAccessToken(data): Observable<any> {
    const fd = new FormData();
    fd.append('username', data.username);
    fd.append('password', data.password);
    fd.append('company_name', data.company_name);
    return this.http.post(BaseApiUrl.LoginGetAccessToken, fd);
  }

  public getAccessTokenFromRefreshToken(refreshToken: string, companyName: string): Observable<any> {
    const fd = new FormData();
    fd.append('refresh_token', refreshToken);
    fd.append('company_name', companyName);
    return this.http.post(BaseApiUrl.LoginGetAccessTokenfromRefreshToken, fd);
  }


  public getAccessTokenFromUserId(userId: string, companyName: string): Observable<any> {
    const fd = new FormData();
    fd.append('company_name', companyName);
    fd.append('user_id', userId);
    return this.http.post(BaseApiUrl.LoginGetAccessTokenfromUserId, fd);
  }


  public logOutUser(data: { username: string, company_name: string }): void {
    this.logout(data)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(
        {
          next: (result) => {
            let notificationDetails = {
              notificationType: 'success',
              notificationMessage: 'Logged out successfully'
            }
            this.notificationMessageDetails$.next(notificationDetails)
            // this.notificationService.showSuccess("You Have Been Logged Out Successfully", "Logout",);
            this.router.navigateByUrl("/login");
          },
          error: (error) => {
            this.notificationService.showError("Something Went Wrong!!!", error);
          },
          complete: () => { }

        }


      )
  }


  public verifyOtp(username: string, email_id: string, verification_otp: string, company_name: string): Observable<any> {
    const fd = new FormData();
    fd.append('username', username);
    fd.append('email_id', email_id);
    fd.append('verification_otp', verification_otp);
    fd.append('company_name', company_name);
    return this.http.post(BaseApiUrl.VerifyOtp, fd);
  }

  public resendOtp(username: string, email_id: string, company_name: string): Observable<any> {
    const fd = new FormData();
    fd.append('username', username);
    fd.append('email_id', email_id);
    fd.append('company_name', company_name);
    return this.http.post(BaseApiUrl.ResendOtp, fd);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next(null);
    this.unsubscriber$.complete();
  }






}
