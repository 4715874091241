import { GlobalSpinnerService } from 'src/app/shared/services/global-spinner.service';
import { CookiesService } from 'src/app/shared/services/cookies.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, finalize } from "rxjs";



@Injectable({
  providedIn: 'root'
})

export class AccessTokenInterceptor implements HttpInterceptor {

  public isAccessTokenExpiredTest: boolean = false;

  constructor(
    private cookiesService: CookiesService,
    private globalSpinnerService: GlobalSpinnerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.globalSpinnerService.isGlobalSpinner$.next(true);

    // console.log("access token interceptor");

    let requestUrlLastFragment = (req.url).split('/')[2];

    if (requestUrlLastFragment === 'login') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    } else if (requestUrlLastFragment === 'get_access_token') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    }
    else if (requestUrlLastFragment === 'verify_otp') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    }
    else if (requestUrlLastFragment === 'resend_otp') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    } else if (requestUrlLastFragment === 'logout') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    } else if (requestUrlLastFragment === 'validatebusinessemail') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    } else if (requestUrlLastFragment === 'gettrialuserinfo') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    } else if (requestUrlLastFragment === 'getaccesstokenfromuserid') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    } else if (requestUrlLastFragment === 'buy_subscription_new_company') {
      return next.handle(req)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }));
    }
    else {


      const accessToken = JSON.parse(this.cookiesService.getCookieByName('accessToken'));

      const modifiedReqest = req.clone(
        {
          headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
        }
      );
      return next.handle(modifiedReqest)
        .pipe(finalize(() => {
          this.globalSpinnerService.isGlobalSpinner$.next(false);
        }))

    }

  }

}
